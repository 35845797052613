// Set some variables.
$font-color: #333;
$mobil-md: 768px;
$mobil-sm: 400px;

* {
  /* font-family: 'Roboto', sans-serif; */
  font-size: 18px;
  font-weight: 300;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: $font-color;
}

h2 {
  font-size: 120%;
}

h3 {
  font-size: 110%;
}

strong {
  font-weight: bold;
}

p, li {
  line-height: 1.5rem;
}

.container {
  max-width: 1000px;
  margin: 0 auto;

  @media screen and (max-width: $mobil-md) {
    width: 100%;
  }
}


.header {
  text-align: center;
  margin-bottom: 3rem;
}

.content {
  padding: 0 1rem;
}

.shop-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: 3rem;

  .item {
    position: relative;
    justify-self: center;
    display: block;
    text-decoration: none;
    color: #333;
    padding: 0.5rem;
    width: 15rem;

    @media (max-width: $mobil-md) {
      width: 100%;
    }

    &:hover {
      background-color: #ffffff;
      border-radius: 0.5rem;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      transition: all 0.3s;
    }
  }

  > div a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .item-name {
    padding: 1rem 0;
  }
}


.shop-gallery .item-image {
  position: relative;
  height: 15rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;

  @media (max-width: $mobil-md) {
    width: 100%;
    height: 15rem;
  }
}

.shop-gallery .sold .item-price {
  color: #ccc;
}

.shop-gallery .sold {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}
h2 .sold {
  display: inline;
  font-style: italic;
}

.footer {
  border-top: 1px solid #ccc;
  margin-top: 6rem;
  text-align: center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 10px 10px 10px 10px #fff;
  background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="%231C274C" stroke-width="1.5" stroke-linecap="round"/><path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="%231C274C" stroke-width="1.5" stroke-linecap="round"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -222222px;
  overflow: hidden;

}

.description {
  margin: 1rem 0;
  font-size: 0.8rem;
  color: #666;
}

.item-price {
  position: relative;
  display: flex;
  justify-content: space-between;
}
  
.paypal-button a {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #333;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  text-decoration: none;
  background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5996 21.57C19.7042 21.57 20.5996 20.6746 20.5996 19.57C20.5996 18.4654 19.7042 17.57 18.5996 17.57C17.495 17.57 16.5996 18.4654 16.5996 19.57C16.5996 20.6746 17.495 21.57 18.5996 21.57Z" stroke="%23000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.59961 21.57C9.70418 21.57 10.5996 20.6746 10.5996 19.57C10.5996 18.4654 9.70418 17.57 8.59961 17.57C7.49504 17.57 6.59961 18.4654 6.59961 19.57C6.59961 20.6746 7.49504 21.57 8.59961 21.57Z" stroke="%23000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 3.55997C2 3.55997 6.64 3.49997 6 7.55997L5.31006 11.62C5.20774 12.1068 5.21778 12.6105 5.33954 13.0929C5.46129 13.5752 5.69152 14.0234 6.01263 14.4034C6.33375 14.7833 6.73733 15.0849 7.19263 15.2854C7.64793 15.4858 8.14294 15.5797 8.64001 15.56H16.64C17.7479 15.5271 18.8119 15.1196 19.6583 14.404C20.5046 13.6884 21.0834 12.7069 21.3 11.62L21.9901 7.50998C22.0993 7.0177 22.0939 6.50689 21.9744 6.017C21.8548 5.52712 21.6242 5.07126 21.3005 4.68467C20.9767 4.29807 20.5684 3.99107 20.1071 3.78739C19.6458 3.58371 19.1438 3.48881 18.64 3.50998H9.94" stroke="%23000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-size: 18px;
  background-position: left center;
  padding: 4px 4px 4px 25px;
  background-repeat: no-repeat;
  z-index: 1000;
}

.ReactModalPortal .paypal-button {
  text-align: center;
  margin-top: 2rem;
  a {
    font-size: 1.5rem;
    background-size: 22px;
  }
}

.filter {
  position: relative;
  margin-bottom: 0.5rem;
  text-align: center;
  
  > div {
    display: inline-block;
    margin: 0 1.5rem 0.5rem 0;
  }

  @media screen and (max-width: $mobil-sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      width: 100%;
      margin: 0 0 0.5rem 0;
    }
    
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 1rem;
    margin-right: 0.5rem;

    input {
      display: none;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 1px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 20px;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 1rem;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    input:checked + .slider {
      background-color: #2196F3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
  }


  .select-container {
    display: inline-block;
    margin-left: 0.2rem;
    width: auto;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  
    // Stack above custom arrow
    z-index: 0;
  
    // Remove dropdown arrow in IE10 & IE11
    // @link https://www.filamentgroup.com/lab/select-css.html
    &::-ms-expand {
      display: none;
    }
  
    // Remove focus outline, will add on alternate element
    outline: none;
  }

  .select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
  
    select,
    &::after {
      grid-area: select;
    }

    width: 5rem;
  
    font-size: 1rem;
    cursor: pointer;
    line-height: 1;
  
    // Optional styles
    // remove for transparency
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  
    // Custom arrow
    &:not(.select--multiple)::after {
      content: "";
      justify-self: end;
      width: 0.8em;
      height: 0.5em;
      background-color: #333;
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
  }
  
  // Interim solution until :focus-within has better support
  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }
}

nav {
  position: relative;
  margin-bottom: 2rem;

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    flex-flow: wrap;
    padding: 0;
    margin: 0;

    li {
      margin: 0 0.5rem;
      white-space: nowrap;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          // text-decoration: dotted;
          border-bottom: 1px dotted #000;
        }
      }
    }
  }
}

.display-item {
  position: relative;
  max-width: 600px;
  margin: 0 auto;

  img {
    display: block;
    margin: 1rem auto;
    width: 100%;
    max-width: 400px;
    height: auto;
  }
}

// paypal button
.market .paypal {
  display: block;
  margin: 2rem auto;
  max-width: 400px;
  text-align: center;
}
.pp-CS5MYNJT3ZXH6{text-align:center;border:none;border-radius:1.5rem;min-width:11.625rem;padding:0 2rem;height:2.625rem;font-weight:bold;background-color:#FFD140;color:#000000;font-family:"Helvetica Neue",Arial,sans-serif;font-size:1rem;line-height:1.25rem;cursor:pointer;}